/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://hy933ixife.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        },
        {
            "name": "db",
            "endpoint": "https://0grryonzfk.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        },
        {
            "name": "proxy",
            "endpoint": "https://0telfxnfoe.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-3:c8d6700e-ae0b-41b1-9a6a-ce25d045ef6b",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_lnogfkwWY",
    "aws_user_pools_web_client_id": "7msseub83l0qlq2o43sv6450fc",
    "oauth": {
        "domain": "paperoffice9c9710b8-9c9710b8-dev.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8080/,https://staging.d2bi6pr5g5u5zt.amplifyapp.com/,https://prod.d2bi6pr5g5u5zt.amplifyapp.com/,https://staging.paperoffice.ops.papernest.com/,https://paperoffice.ops.papernest.com/,https://dev.d2bi6pr5g5u5zt.amplifyapp.com/,https://dev.paperoffice.ops.papernest.com/",
        "redirectSignOut": "http://localhost:8080/,https://staging.d2bi6pr5g5u5zt.amplifyapp.com/,https://prod.d2bi6pr5g5u5zt.amplifyapp.com/,https://staging.paperoffice.ops.papernest.com/,https://paperoffice.ops.papernest.com/,https://dev.d2bi6pr5g5u5zt.amplifyapp.com/,https://dev.paperoffice.ops.papernest.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-3",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "profiles-dev",
            "region": "eu-west-3"
        }
    ]
};


export default awsmobile;
