
import { AppNavigation } from '@/shared/services/navigation';

/** Define the navigation hierarchy */
export const offerCatalogNavigationConfig: AppNavigation = {
    title: 'Offer-catalog',
    icon: 'home_storage',
    needGroup: 'ReadOnly',
    items: [
        {
            title: 'OFFERS',
            items: [
                {
                    title: 'Box',
                    link: 'offer-catalog-routing-box',
                    icon: 'router',
                    needGroup: 'ReadOnly',
                },
                {
                    title: 'TV',
                    link: 'offer-catalog-routing-tv',
                    icon: 'live_tv',
                    needGroup: 'ReadOnly',
                },
                /*  {
          title: "Energy",
          link: "offer-catalog-routing-energy",
          icon: "wind_power",
        },
        {
          title: "Cellular",
          link: "offer-catalog-routing-cellular",
          icon: "cell_tower",
        },
        {
          title: "Home assistance",
          link: "offer-catalog-routing-home-assistance",
          icon: "home_pin",
        },
        {
          title: "Home maintenance",
          link: "offer-catalog-routing-home-maintenance",
          icon: "engineering",
        },
        {
          title: "Home insurance",
          link: "offer-catalog-routing-home-insurance",
          icon: "assured_workload",
        },
        {
          title: "Bundle Box+Cellular",
          link: "offer-catalog-routing-bundle-box-cellular",
          icon: "package",
        },
        {
          title: "Plan",
          link: "offer-catalog-routing-plan",
          icon: "topic",
        },
        {
          title: "Price grid's",
          link: "offer-catalog-routing-price-grid",
          icon: "grid_on",
        },
        {
          title: "Price's",
          link: "offer-catalog-routing-price",
          icon: "euro",
        },*/
            ],
        },
    ],
};
